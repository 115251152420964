import React, { useState, useEffect } from "react";
export const GlobalStateContext = React.createContext();
export const GlobalDispatchContext = React.createContext();

//initial state
const initialState = {
  viewport: "",
  errors: [],
};
function debounce(fn, ms) {
  let timer;
  return (_) => {
    clearTimeout(timer);
    timer = setTimeout((_) => {
      timer = null;
      fn.apply(this, arguments);
    }, ms);
  };
}
// !reducer function takes in state and action and updates global state

function reducer(state, action) {
  switch (action.type) {
    case "SET_RESET":
      return initialState;
    case "SET_VIEWPORT": {
      return {
        ...state,
        viewport: action.viewport,
      };
    }
    default:
      throw new Error("bad action type");
  }
}

// global context provider that we use in other components to access state and dispatch
const GlobalContextProvider = ({ children }) => {
  // make const state and dispatch
  const [state, dispatch] = React.useReducer(reducer, initialState);

  const w =
    window.innerWidth ||
    document.documentElement.clientWidth ||
    document.body.clientWidth;
  const [width, setDimensions] = useState({
    w,
  });
  function sizeUp() {
    // ! WIDTHS //
    //! 400 MAX SMALLMOBILE
    if (width.w <= 400 && state.viewport !== "smallMobile") {
      dispatch({ type: "SET_VIEWPORT", viewport: "smallMobile" });
      //! 400 MIN MOBILE
    } else if (width.w > 400 && width.w <= 600 && state.viewport !== "mobile") {
      dispatch({ type: "SET_VIEWPORT", viewport: "mobile" });
      //! 600 MIN
    } else if (width.w > 600 && width.w <= 900 && state.viewport !== "tablet") {
      dispatch({ type: "SET_VIEWPORT", viewport: "tablet" });
      //! 900 MIN
    } else if (
      width.w > 900 &&
      width.w <= 1200 &&
      state.viewport !== "laptop"
    ) {
      dispatch({ type: "SET_VIEWPORT", viewport: "laptop" });
      //! 1200 MIN
    } else if (
      width.w > 1200 &&
      width.w <= 1600 &&
      state.viewport !== "largeLaptop"
    ) {
      dispatch({ type: "SET_VIEWPORT", viewport: "largeLaptop" });
      //! 1600 MIN
    } else if (width.w > 1600 && state.viewport !== "desktop") {
      dispatch({ type: "SET_VIEWPORT", viewport: "desktop" });
    } else {
      console.log(` State.Viewport: ${state.viewport}`, "Cherry Pick");
    }
  }
  useEffect(() => {
    sizeUp();
    const debouncedHandleResize = debounce(function handleResize() {
      const w =
        window.innerWidth ||
        document.documentElement.clientWidth ||
        document.body.clientWidth;
      setDimensions({
        w,
      });
      sizeUp();
    }, 1000);

    window.addEventListener("resize", debouncedHandleResize);

    return (_) => {
      window.removeEventListener("resize", debouncedHandleResize);
    };
  });

  return (
    // wrap providers around children and set values to state and dispatch
    <GlobalStateContext.Provider value={state}>
      <GlobalDispatchContext.Provider value={dispatch}>
        {children}
      </GlobalDispatchContext.Provider>
    </GlobalStateContext.Provider>
  );
};

export default GlobalContextProvider;
