const marsTheme = {
  colors: {
    marsRed: "#B94700",
    midNiteNavy: "#000032",
    cadetBlue: "#6B8F9C",
    sandStone: "#CC6633",
    clay: "#F1FAF0",
    white: "#E8EDE8",
    black: "#000",
  },
  typography: {
    fontSizes: [
      "0.875rem",
      "1rem",
      "1.25rem",
      "1.5rem",
      "1.875rem",
      "2.25rem",
      "3rem",
      "4rem",
      "4.5rem",
      "5rem",
      "5.5rem",
      "6rem",
      "10rem",
    ],
  },
  spacing: null,
};

export default marsTheme;
